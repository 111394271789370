import React, { Component } from 'react';
import { NavMenu } from '../components/NavMenu';
import Sidebar from '../components/Sidebar/Sidebar.tsx';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className="d-flex flex-column min-vh-100">
        {/* NavMenu fixed at the top */}
        <div className="position-fixed w-100" style={{ top: 0, zIndex: 1030 }}>
          <NavMenu />
        </div>
        
        {/* Main container */}
        <div className="d-flex" style={{ marginTop: '56px' }}> {/* Adjust marginTop based on your NavMenu height */}
          {/* Sidebar */}
          <div className="position-fixed h-100 bg-white border-right" 
               style={{ 
                 width: '250px', 
                 top: '56px',
                 overflowY: 'auto',
                 bottom: 0,
                 left: 0,
                 
               }}>
            <Sidebar />
          </div>
          
          {/* Main content */}
          <div className="flex-grow-1" style={{ marginLeft: '250px' }}>
            <main className="p-4">
              {this.props.children}
            </main>
          </div>
        </div>
      </div>
    );
  }
}