// src/App.tsx
import React from "react";
import "./styles/App.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import routes, { RouteConfig } from "./config/routes";
import { Layout } from "pages/Layout";
import { PageLayout } from "components/authentication/PageLayout";

const renderRoutes = (routes: RouteConfig[]) => {
  return routes.map((route) => (
    <React.Fragment key={route.path}>
      <Route path={route.path} element={<route.component />} />
      {route.children && renderRoutes(route.children)}
    </React.Fragment>
  ));
};

const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <Layout>
          <Routes>
            {renderRoutes(routes)}
          </Routes>
        </Layout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate> 
        <PageLayout />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return <MainContent />;
}