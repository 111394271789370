// src/components/Sidebar/Sidebar.tsx
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { routes, RouteConfig } from "../../config/routes";
import "./Sidebar.css";

const SidebarItem = ({ route, level = 1 }: { route: RouteConfig; level?: number }) => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const hasChildren = route.children && route.children.length > 0;

  const isActive = (path: string) => {
    return location.pathname === path || location.pathname.startsWith(path + '/');
  };

  const isChildActive = () => {
    return route.children?.some(child => isActive(child.path));
  };

  return (
    <div className={`sidebar-section nav-level-${level}`}>
      <div 
        className={`sidebar-section-title ${isActive(route.path) || isChildActive() ? "active" : ""}`}
        onClick={() => hasChildren && setExpanded(!expanded)}
      >
        <Link 
          to={route.path} 
          className={`d-flex align-items-center ${isActive(route.path) ? "active-link" : ""}`}
        >
          {route.icon && <i className={`bi ${route.icon} me-2`}></i>}
          <span>{route.name}</span>
        </Link>
        {hasChildren && (
          <i className={`bi ${expanded ? "bi-chevron-down" : "bi-chevron-right"} ms-2`}></i>
        )}
      </div>
      
      {hasChildren && expanded && (
        <div className="sidebar-children">
          {route.children!.map((child, index) => (
            <SidebarItem key={index} route={child} level={level + 1} />
          ))}
        </div>
      )}
    </div>
  );
};

const Sidebar = () => {
  return (
    <nav className="sidebar-nav">
      {routes.map((route, index) => (
        <SidebarItem key={index} route={route} />
      ))}
    </nav>
  );
};

export default Sidebar;