import React, { useState, useRef } from 'react';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import useProfileData from '../services/ProfileDataService';
import { PageLayout } from './authentication/PageLayout';
import './NavMenu.css';

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { userName, profileImage } = useProfileData();
  const [show, setShow] = useState(false);
  const anchor = useRef(null);

  const togglePopup = () => setShow(prev => !prev);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <Navbar className="custom-navbar navbar-expand-md navbar-toggleable-md">
      <a href="/" className="navContainer_home roboto-19-700">
        <img className="logo-icon" alt="" src="/logo.svg" />  
        <span>MTOQueue</span>
      </a>        
      <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!collapsed} navbar>
        <div className="navbar-nav flex-grow d-flex align-items-center justify-content-end" style={{ gap: '12px' }}>
          {/* <a href="https://daacloud.sharepoint.com/sites/Eng-MEDCPortal">
            <button className='buttonPrimary'>DAA Engineering Portal</button>
          </a> */}
          <div className="d-flex align-items-center position-relative">
            <button 
              className="roboto-15-500 bg-white secondary-button"
              style={{
                borderRadius: '20px', 
                border: '1px solid white',
                cursor: 'pointer',
                padding: '0 8px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }} 
              onClick={togglePopup}
              ref={anchor}
              title={userName}
            >
              <img
                style={{width: '32px', height: '32px', borderRadius: '32px'}}
                alt=""
                src={profileImage}
              />
              <svg 
                style={{
                  transform: show ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.2s'
                }}
                xmlns="http://www.w3.org/2000/svg" 
                width="9" 
                height="6" 
                viewBox="0 0 9 6" 
                fill="none"
              >
                <path d="M4.04109 5.355L0.444093 1.2435C0.0195932 0.75975 0.364593 2.75779e-07 1.00884 2.75779e-07H8.20284C8.34703 -0.000123345 8.48819 0.0413159 8.60943 0.119356C8.73067 0.197396 8.82684 0.308728 8.88643 0.44002C8.94602 0.571312 8.96651 0.716999 8.94543 0.859633C8.92435 1.00227 8.86261 1.1358 8.76759 1.24425L5.17059 5.35425C5.1002 5.43481 5.01338 5.49937 4.91598 5.54361C4.81857 5.58785 4.71283 5.61074 4.60584 5.61074C4.49886 5.61074 4.39312 5.58785 4.29571 5.54361C4.19831 5.49937 4.11149 5.43481 4.04109 5.35425V5.355Z" fill="#1C2325"/>
              </svg>
            </button>
            {show && (
              <div style={{ 
                position: 'absolute', 
                top: '100%', 
                right: 0, 
                zIndex: 1100,
                background: 'white',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                borderRadius: '4px',
                marginTop: '8px',
                minWidth: '200px',
                padding: '8px'
              }}>
                <PageLayout />
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </Navbar>
  );
};