// src/config/routes.ts
import Home from '../pages/Home';
import About from '../pages/About';

export interface RouteConfig {
  path: string;
  name: string;
  icon?: string;
  component: React.ComponentType<any>;
  children?: RouteConfig[];
}

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Request List',
    icon: 'bi-house',
    component: Home
    // children: [
    //   {
    //     path: '/home',
    //     name: 'RequestList',
    //     component: Home
    //   }
    // ]
  },
  {
    path: '/About',
    name: 'About',
    icon: 'bi-house',
    component: About,
    
  }
];

export default routes; 