import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { truncateEmail } from '../Utilities/utils';
import { useLocalStorage } from 'usehooks-ts';
import axios from '../axiosConfig';

const PROFILE_IMAGE = 'PROFILE_IMAGE';
const PROFILE_IMAGE_DATA = 'PROFILE_IMAGE_DATA';

const useProfileData = (fetchProfileImage = true) => {
  const [token, setToken] = useState();
  const [profileImage, setProfileImage] = useLocalStorage(PROFILE_IMAGE, '/DefaultProfile.png');
  const [profileImageData, setProfileImageData] = useLocalStorage(PROFILE_IMAGE_DATA, null);
  const [userName, setuserName] = useState();
  const [userEmail, setuserEmail] = useState();
  const { instance, accounts } = useMsal();
  const firstAccount = accounts[0];

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Obtener token para la API principal
        const apiResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: firstAccount
        });

        // Obtener token específico para Microsoft Graph si se necesita la foto
        if (fetchProfileImage && profileImageData === null) {
          try {
            const graphResponse = await instance.acquireTokenSilent({
              scopes: ['User.Read'],
              account: firstAccount
            });

            const graphEndpoint = 'https://graph.microsoft.com/v1.0/me/photo/$value';
            const imageResponse = await fetch(graphEndpoint, {
              headers: {
                Authorization: `Bearer ${graphResponse.accessToken}`,
              },
            });

            if (imageResponse.ok) {
              const blob = await imageResponse.blob();
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onload = () => {
                setProfileImage(reader.result);
                setProfileImageData({ data: blob });
              };
            }
          } catch (graphError) {
            console.error('Error fetching profile image:', graphError);
            setProfileImage('/DefaultProfile.png');
            setProfileImageData(null);
          }
        }

        // Configurar axios con el token para la API principal
        axios.defaults.headers.common['Authorization'] = `Bearer ${apiResponse.accessToken}`;
        
        // Actualizar el estado con la información del usuario
        setToken(firstAccount.idToken);
        setuserName(apiResponse.account.name);
        setuserEmail(truncateEmail(apiResponse.account.username, 50));

      } catch (error) {
        console.error('Error in profile data fetch:', error);
        setProfileImage('/DefaultProfile.png');
        setProfileImageData(null);
      }
    };

    if (firstAccount) {
      fetchProfileData();
    }
  }, [instance, firstAccount, fetchProfileImage, profileImageData, setProfileImage, setProfileImageData]);

  return { token, profileImage, userName, userEmail, profileImageData };
};

export default useProfileData;